// extracted by mini-css-extract-plugin
export var buildCodeText = "home-module--buildCodeText--GGb+N";
export var downArrow = "home-module--downArrow--rjgSi";
export var exclamation = "home-module--exclamation--HyR5f";
export var expBottomSpacer = "home-module--expBottomSpacer--W6bhp";
export var expHeader = "home-module--expHeader--GW6+j";
export var expHeaderSpace = "home-module--expHeaderSpace--CMadj";
export var expImgWrapper = "home-module--expImgWrapper--mzhMQ";
export var expItem = "home-module--expItem--CqTW3";
export var expItemBotText = "home-module--expItemBotText--gv6Mi";
export var expItemImg = "home-module--expItemImg--CDZXv";
export var expItemNumber = "home-module--expItemNumber--UBW-A";
export var expItemText = "home-module--expItemText--E5RH9";
export var expItemUpText = "home-module--expItemUpText--fNB7U";
export var expItems = "home-module--expItems--p9lf6";
export var experimentsContainer = "home-module--experimentsContainer--hO4LH";
export var experimentsContent = "home-module--experimentsContent--Jx0V9";
export var heroContainer = "home-module--heroContainer--VZ7tY";
export var heroLeft = "home-module--heroLeft--Mg3UJ";
export var heroText = "home-module--heroText--KInp3";
export var imRyan = "home-module--imRyan--zJmtq";
export var imageTag = "home-module--imageTag--7mhHB";
export var mobileBuildCode = "home-module--mobileBuildCode--G14zq";
export var mobileImRyan = "home-module--mobileImRyan--mrofU";
export var mobileSubtext = "home-module--mobileSubtext--3+JMp";
export var orthoBox = "home-module--orthoBox--XLwa1";
export var pageContainer = "home-module--pageContainer--h4ygj";
export var projectDescription = "home-module--projectDescription--f9qj1";
export var projectImage = "home-module--projectImage--LnYUT";
export var projectImageInner = "home-module--projectImageInner--tU7hm";
export var projectItem = "home-module--projectItem--RhSDV";
export var projectTitle = "home-module--projectTitle--hErzI";
export var projectsContainer = "home-module--projectsContainer--wJg54";
export var projectsGrid = "home-module--projectsGrid--wiC4Q";
export var projectsHeader = "home-module--projectsHeader--AeOFO";
export var purpleText = "home-module--purpleText--fPRMz";
export var workArrow = "home-module--workArrow--FmZkj";
export var workText = "home-module--workText--coHkY";