import * as React from "react"
import * as FooterStyles from "./styles/footer.module.css"
import rainbow from "../assets/footer/rainbow.svg"
import github from "../assets/footer/github.svg"
import linkedin from "../assets/footer/linkedIn.svg"
import email from "../assets/footer/email.svg"
import itch from "../assets/footer/itch.svg"

const Footer = () => (
    <div id={FooterStyles.footer}>
        <div id={FooterStyles.left}>
            <div id={FooterStyles.rainbow}>
                You made it! <img src={rainbow} alt="rainbow"/>
            </div>
            <div class={FooterStyles.subText}>
                Thanks for checking out my work.
            </div>
            <div class={FooterStyles.subText}>
                Don’t be shy to reach out if you’d like to chat or collaborate!
            </div>
            <div class={FooterStyles.subText} id={FooterStyles.mobileShiftedText}>
                You can find me at the links below, or take a peek at my <span class={FooterStyles.linkBlue}><a href={"../../ResumePortfolio.pdf"}>resume.</a></span>
            </div>
        </div>

        <div id={FooterStyles.right}>
            <div class={FooterStyles.rightText}>
                Find me here
            </div>
            <div id={FooterStyles.socialImagesContainer}>
                <a href="https://github.com/rwinstead" target="_blank" rel="noreferrer">
                    <img src={github} alt="github logo"/>
                </a>
                <a href="https://www.linkedin.com/in/ryan-winstead-207a0a103/" target="_blank" rel="noreferrer">
                    <img src={linkedin} alt="linkedin logo"/>
                </a>
                <a href="https://rwinstead.itch.io/" target="_blank" rel="noreferrer">
                    <img src={itch} alt="itch.io logo"/>
                </a>
                <a href="mailto:hello@ryanwinstead.com">
                    <img src={email} alt="email icon" />
                </a>
            </div>
            <div class={FooterStyles.rightText}>
                Or, take a peek at my <span class={FooterStyles.linkBlue}><a href={"../../ResumePortfolio.pdf"}>resume</a></span>
            </div>
        </div>
        <div id={FooterStyles.cc}>
            © 2021 Ryan Winstead 
        </div>
    </div>

)

export default Footer