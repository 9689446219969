// extracted by mini-css-extract-plugin
export var cc = "footer-module--cc--0WG2C";
export var footer = "footer-module--footer--UiIQH";
export var left = "footer-module--left--+Nxjk";
export var linkBlue = "footer-module--linkBlue--D2thX";
export var mobileShiftedText = "footer-module--mobileShiftedText--PU6ne";
export var rainbow = "footer-module--rainbow--PT75b";
export var right = "footer-module--right--M3WpY";
export var rightText = "footer-module--rightText--J8Dk+";
export var socialImagesContainer = "footer-module--socialImagesContainer--dkLtJ";
export var subText = "footer-module--subText--C3MXl";