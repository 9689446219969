import * as React from "react"
import { Link } from "gatsby"
import * as homeStyles from "../styles/home.module.css"
import * as headerStyles from "./styles/header.module.css"
import navBars from "../assets/nav/navBars2.svg"
import navX from "../assets/nav/navX.svg"
import { Link as ScrollLink} from "react-scroll"


export default class Header extends React.Component {

constructor(props) {
    super(props);

    this.state = {
      showMenu : false,
    };

    this.showMenu = this.showMenu.bind(this);
}

showMenu(event) {
  event.preventDefault();
  
  this.setState({
    showMenu: !this.state.showMenu,
  });
}

render () {

  const showMenu = this.state.showMenu;
  let navButton;
  let navMenu;

  if(showMenu) {
    navButton = 
      <section class={headerStyles.navMobile}>
        <span>
          <input type="image" src={navBars} alt="Menu" width="32px" height="16px" color="#626262" class={headerStyles.hamburgerFade} />
        </span>
        <span>
          <input type="image" src={navX} alt="Close menu X" width="20px" height="20px" onClick={this.showMenu} class={headerStyles.menuX} />
        </span>
      </section>;

    navMenu =
    <div id={headerStyles.navMobileContainer}>
      <div id={headerStyles.navMobileMenuInView}>
        <div class={headerStyles.mobileMenuItems}>
          <ul>
              <CreateLink
                  home={this.props.home}
                  mobile={true}
                  location={homeStyles.projectsContainer}
                  duration={500}
                  offset={-25}
                  text={"Projects"}
                  click={this.showMenu}
              >
              </CreateLink>

            <CreateLink
                home={this.props.home}
                mobile={true}
                location={homeStyles.experimentsContainer}
                duration={1000}
                offset={0}
                text={"Experiments"}
                click={this.showMenu}
            >
            </CreateLink>
            <Link
                to="/about-me"
            >
              About me
            </Link>
          </ul>
        </div>
      </div>
      </div>;
  }

  else {
   navButton = 
    <section class={headerStyles.navMobile}>
        <span>
          <input type="image" src={navBars} alt="Menu" width="32px" height="16px" color="#626262" onClick={this.showMenu} class={headerStyles.hamburger} />
        </span>
        <span>
          <input type="image" src={navX} alt="Close menu X" width="20px" height="20px" class={headerStyles.menuXFade} />
        </span>
    </section>;

    navMenu =
    <div id={headerStyles.navMobileContainerInvis}>
      <div id={headerStyles.navMobileMenu}>
          <div class={headerStyles.mobileMenuItems}>
            <ul>
              <ScrollLink
                  to={homeStyles.projectsContainer}
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-25}
                  onClick={this.showMenu}
              >
              Projects
              </ScrollLink>
              <ScrollLink
                to={homeStyles.experimentsContainer}
                spy={true}
                smooth={true}
                duration={1000}
                onClick={this.showMenu}
                delay={0}
            >
                Experiments
              </ScrollLink>
              <Link
                  to="/about-me"
              >
                About me
              </Link>
            </ul>
          </div>
      </div>
    </div>;
  }

  return (
    <div>
    <div id={headerStyles.container}>
      <Link
        to="/"
      >
        <section id={headerStyles.logo}>
          RW
        </section>
      </Link>

      <section id={headerStyles.nav}>
        <CreateLink 
          home={this.props.home}
          mobile={false}
          location={homeStyles.projectsContainer}
          duration={200}
          offset={-45}
          text={"projects"}
          delay={0}
          >
        </CreateLink>

          <CreateLink
            home={this.props.home}
            mobile={false}
            location={homeStyles.experimentsContainer}
            duration={200}
            text={"experiments"}
            offset={0}
            delay={0}
          >
          </CreateLink>

        <div class={headerStyles.navItem}>
          <Link
            to="/about-me"
          >
            about me
          </Link>
        </div>
      </section>

        {navButton}
        {navMenu}
    </div>

</div>
    );
  }
}

function CreateLink(props) {
  if(props.home && !props.mobile) {
    return (
      <div class={headerStyles.navItem} >
        <ScrollLink
        to={props.location}
        spy={true}
        smooth={true}
        duration={props.duration}
        offset={props.offset}
      >
        {props.text}
      </ScrollLink>
    </div>
    );
  }

  if(props.home && props.mobile) {
      return (
        <ScrollLink
        to={props.location}
        spy={true}
        smooth={true}
        duration={props.duration}
        offset={props.offset}
        onClick={props.click}
      >
        {props.text}
      </ScrollLink>
      );
  }

  if(!props.home && !props.mobile) {
    return (
      <div class={headerStyles.navItem} >
        <Link
        to={"/#" + props.location}
      >
        {props.text}
      </Link>
    </div>
    );

  }

  if(!props.home && props.mobile) {
    return (
      <Link
      to={"/#" + props.location}
    >
      {props.text}
    </Link>
    );

  }

}